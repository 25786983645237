import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/stuff/Dirtyness";
import { ImpersonationMode, UserRole } from "@/model/Enums";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

let router: VueRouter | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void) {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    else {
        dirtyness.reset();
        return next();
    }
}

// if we refresh the browser, we'll lose any variables, so...
export function reEstablishImpersonationMode(route?: Route) {
    if(router == null) return;
    route = route || router.currentRoute;
    const path = route.fullPath.toLowerCase();
    const user = store.state.signedInUser;

    console.log("reEstablishImpersonationMode - user=", user);

    if(!user) return; // we need to have a user
    if(user.role !== UserRole.Cqms && user.role !== UserRole.Admin) return; // need to be right role to impersonate
    if(store.state.impersonationMode !== ImpersonationMode.None) return; // already set?

    console.log("reEstablishImpersonationMode - game on...", path);
    
    if(path.indexOf("/supplierzone") > -1) {
        store.dispatch("setImpersonationMode", {
            mode: ImpersonationMode.Supplier,
            id: user.supplierID
        });
    }
    else if(path.indexOf("/buyerzone") > -1) {
        store.dispatch("setImpersonationMode", {
            mode: ImpersonationMode.Buyer,
            id: user.buyerID
        });
    }
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    console.log("....beforeEnterGuard");

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }
    
    try {
        // get user from session storage
        await store.dispatch("loadSignedInUser");
    } catch (e) {
        console.log("@@@ loadSignedInUser failed ");
        console.error(e);
    } 
    
    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        // were we in impersonation mode?
        reEstablishImpersonationMode(to);
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/signIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

const supplierModuleMatrixComponent: Promise<typeof import("*.vue")> = import("../views/SupplierModuleMatrix.vue");
const reports: Promise<typeof import("*.vue")> = import("../views/Reports.vue");
const regions: Promise<typeof import("*.vue")> = import ("../views/Regions.vue");
const region: Promise<typeof import("*.vue")> = import("../views/Region.vue");

routes.push({ path: "/", name: "HomeSignIn", meta: { layout: "blank" }, component: () => import("../views/HomeSignIn.vue") });

// CQMS mode
routes.push({ path: "/home", name: "Dashboard", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Dashboard.vue") });
routes.push({ path: "/messages", name: "Messages", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Messages.vue") });
routes.push({ path: "/profile", name: "Profile", meta: { layout: "signed-in" }, component: () => import("../views/Profile.vue") });
routes.push({ path: "/buyers", name: "Buyers", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Buyers.vue") });
routes.push({ path: "/buyer/:buyerID", name: "Buyer", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Buyer.vue") });
routes.push({ path: "/regions", name: "Regions", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => regions });
routes.push({ path: "/region/:regionID", name: "Region", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => region });
routes.push({ path: "/survey/:srsID", name: "Survey", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Survey.vue") });
routes.push({ path: "/uploadTest/:documentID", name: "UploadTest", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/UploadTest.vue") });
routes.push({ path: "/suppliers", name: "Suppliers", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Suppliers.vue") });
routes.push({ path: "/supplier/:supplierID", name: "Supplier", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Supplier.vue") });
routes.push({ path: "/supplierModuleMatrix", name: "supplierModuleMatrix", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => supplierModuleMatrixComponent });
routes.push({ path: "/reports", name: "reports", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => reports });
routes.push({ path: "/emailTemplates", name: "emailTemplates", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/EmailTemplates.vue") });
routes.push({ path: "/Resources", name: "Resources", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/TemplateDocuments.vue") });

// Supplier zone
routes.push({ path: "/supplierZone", name: "SupplierZoneHome", redirect: "/supplierZone/todo" });
routes.push({ path: "/supplierZone/messages", name: "SupplierZoneMessages", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Messages.vue") });
routes.push({ path: "/supplierZone/profile", name: "SupplierZoneProfile", meta: { layout: "signed-in" }, component: () => import("../views/Profile.vue") });
routes.push({ path: "/supplierZone/details", name: "SupplierZoneDetails", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/supplierZone/Supplier.vue") });
routes.push({ path: "/supplierZone/Resources", name: "SupplierZoneResources", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/TemplateDocuments.vue") });
routes.push({ path: "/supplierZone/status", name: "SupplierZoneStatus", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/supplierZone/Modules.vue") });
routes.push({ path: "/supplierZone/todo", name: "SupplierTodo", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/supplierZone/Modules.vue") });
routes.push({ path: "/supplierZone/modules", name: "SupplierZoneModules", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/supplierZone/Modules.vue") });

// Buyer zone (note users component is same as the notmal admin one but suppliers one is specific)
routes.push({ path: "/buyerZone", name: "BuyerZoneHome", redirect: "/buyerZone/supplierModuleMatrix" });
routes.push({ path: "/buyerZone/home", name: "BuyerZoneDashboard", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Dashboard.vue") });
routes.push({ path: "/buyerZone/Messages", name: "BuyerZoneMessages", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Messages.vue") });
routes.push({ path: "/buyerZone/Profile", name: "BuyerZoneProfile", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Profile.vue") });
routes.push({ path: "/buyerZone/suppliers", name: "BuyerZoneSuppliers", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/buyerZone/Suppliers.vue") });
routes.push({ path: "/buyerZone/supplier/:supplierID", name: "BuyerZoneSupplier", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/buyerZone/Supplier.vue") });
routes.push({ path: "/buyerZone/users", name: "BuyerZoneUsers", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Users.vue") });
routes.push({ path: "/buyerZone/supplierModuleMatrix", name: "buyerZoneSupplierModuleMatrix", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => supplierModuleMatrixComponent });
routes.push({ path: "/buyerZone/reports", name: "buyerZoneReports", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => reports });
routes.push({ path: "/buyerZone/regions", name: "buyerZoneRegions", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => regions });
routes.push({ path: "/buyerZone/region/:regionID", name: "buyerZoneRegion", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => region });

// admin-ey stuff
routes.push({ path: "/users", name: "Users", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Users.vue") });
routes.push({ path: "/lookups/:groupID", name: "Lookups", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Lookups.vue") });
routes.push({ path: "/trades", name: "Trades", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Trades.vue") });
routes.push({ path: "/scopes", name: "Scopes", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Scopes.vue") });
routes.push({ path: "/modules", name: "Modules", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Modules.vue") });
routes.push({ path: "/NonWorkingDays", name: "NonWorkingDays", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/NonWorkingDays.vue") });
routes.push({ path: "/insuranceTypes", name: "InsuranceTypes", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/InsuranceTypes.vue") });
routes.push({ path: "/notifications", name: "Notifications", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Notifications.vue") });
routes.push({ path: "/TermsAndConditions", name: "TermsAndConditions", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/TermsAndConditions.vue") });

// misc non-signed-in stuff
routes.push({ path: "/PrivacyPolicy", name: "PrivacyPolicy", meta: { layout: "signed-out" }, component: () => import("../views/PrivacyPolicy.vue") });
routes.push({ path: "/signin", name: "SignIn", meta: { layout: "signed-out" }, component: () => import("../views/SignIn.vue") });
routes.push({ path: "/resetPassword/:key", name: "ResetPassword", meta: { layout: "signed-out" }, component: () => import("../views/ResetPassword.vue") });
routes.push({ path: "/Error", name: "Error500", meta: { layout: "signed-out" }, component: () => import("../views/Error500.vue") });

// This needs to be the **last** route
routes.push({ path: "*", meta: { layout: "signed-out" },  component: () => import("../views/Error404.vue") });

//
// -- create the router
//

router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

// router.beforeEach(async (to: Route, from: Route, next: (to?: string | void) => void) => {
//     reEstablishImpersonationMode(to);
//     console.log("@@@@@ beforeEach @@@ to.fullPath", to.fullPath);
//     next();
// });

// router.afterEach(async (to: Route, from: Route) => {
//     reEstablishImpersonationMode(to);
//     console.log("@@@@@ afterEach @@@ to.fullPath", to.fullPath);
// });

export function goToIntendedUrl() {
    if(!router) return;
    if (!intendedUrl) router.replace("/Messages"); // default in case not defined (?)
    router.replace(intendedUrl as RawLocation);
}

export default router as VueRouter;
